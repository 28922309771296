<template>
  <div>
    <DxDataGrid :data-source="dataSource" :show-borders="true">
      <DxPaging :page-size="5" />
      <DxColumn data-field="createdAt" caption="Tarih" data-type="datetime" />
      <DxColumn data-field="status" caption="Durum">
        <DxLookup :data-source="orderTypes" value-expr="id" display-expr="text" />
      </DxColumn>
      <DxColumn data-field="note" caption="Mesaj" />
    </DxDataGrid>
  </div>
</template>
    
<script>
import DxDataGrid, { DxColumn,DxLookup } from 'devextreme-vue/data-grid';
import appInfo from "@/app-info";
import { createStore } from 'devextreme-aspnet-data-nojquery';

export default {
  components: {
    DxDataGrid,
    DxColumn,
    DxLookup
  },
  props: {
    orderId: {
      type: String,
      default: null
    }
  },
  setup(props) {
    const url = `${appInfo.baseUrl}/`;
    const dataSource = createStore({
      key: 'id',
      loadUrl: `${url}OrderDetail/HistoryRead/${props.orderId}`,
    });

    const orderTypes = [
      { text: "Bekleyen Siparişler", id: 0 },
      { text: "Teslim Edilmiş Siparişler", id: 1 },
      { text: "İptal Edilen Siparişler", id: 2 },
      { text: "Kapatılan Siparişler", id: 3 },
    ];

    return {
      dataSource,
      orderTypes
    };
  }
};
</script>