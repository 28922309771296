<template>
    <div>
        <DxDataGrid :data-source="dataSource" :show-borders="true">

            <dx-editing :allow-updating="true" :allow-adding="true" :allow-deleting="true" mode="popup">
                <dx-popup :drag-enabled="true" :show-title="true" :width="700" height="auto" title="Yeni Varyant Ekle" />
                <dx-form>
                    <dx-item :col-count="2" :col-span="2" item-type="group">
                        <dx-item data-field="code" />
                        <dx-item data-field="name" />
                    </dx-item>
                </dx-form>
            </dx-editing>

            <DxPaging :page-size="5" />
            <DxColumn data-field="code" caption="Kod" />
            <DxColumn data-field="name" caption="İsim" />

            <DxMasterDetail :enabled="true" template="master-detail" />
            <template #master-detail="{ data }">
                <AttributeValues :master-detail-data="data" />
            </template>
        </DxDataGrid>
    </div>
</template>
    
<script>
import DxDataGrid, { DxColumn, DxPopup, DxEditing, DxForm, DxItem, DxMasterDetail } from 'devextreme-vue/data-grid';
import appInfo from "@/app-info";
import { createStore } from 'devextreme-aspnet-data-nojquery';
import AttributeValues from './attribute-values.vue';

export default {
    components: {
        DxDataGrid,
        DxColumn,
        DxPopup,
        DxEditing,
        DxForm,
        DxItem,
        DxMasterDetail,
        AttributeValues
    },
    props: {
        productId: {
            type: String,
            default: null
        }
    },
    setup(props) {
        const url = `${appInfo.baseUrl}/`;
        const dataSource = createStore({
            key: 'id',
            loadUrl: `${url}ProductAttribute/Read/${props.productId}`,
            insertUrl: `${url}ProductAttribute/Create/${props.productId}`,
            updateUrl: `${url}ProductAttribute/Update`,
            deleteUrl: `${url}ProductAttribute/Delete`,
        });

        return {
            dataSource
        };
    }
};
</script>