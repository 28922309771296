<template>
  <div>
    <h2 class="content-block">Menüler</h2>

    <dx-data-grid class="dx-card wide-card" :data-source="dataSource" :show-borders="false" :focused-row-enabled="true"
      :column-auto-width="true">
      <dx-paging :page-size="10" />
      <dx-pager :show-page-size-selector="true" :show-info="true" />
      <dx-filter-row :visible="true" />

      <dx-editing :allow-updating="true" :allow-adding="true" :allow-deleting="true" mode="popup">
        <dx-popup :show-title="true" :width="350" height="auto" title="Yeni Marka Ekle" />
        <dx-form>
          <dx-item :col-count="1" :col-span="2" item-type="group">
            <dx-item data-field="icon">
              <dx-required-rule message="Zorunlu Alan" />
            </dx-item>
            <dx-item data-field="name">
              <dx-required-rule message="Zorunlu Alan" />
            </dx-item>
            <dx-item data-field="href">
              <dx-required-rule message="Zorunlu Alan" />
            </dx-item>
            <dx-item data-field="parentId" />
          </dx-item>
        </dx-form>
      </dx-editing>

      <dx-column data-field="id" :visible="false" />

      <dx-column data-field="icon" caption="Simge" />

      <dx-column data-field="name" caption="Başlık" />

      <dx-column data-field="slug" caption="Url" />

      <dx-column data-field="href" caption="Hedef" />

      <dx-column data-field="parentId" caption="Üst Menü">
        <dx-lookup :data-source="dataSource" valueExpr="id" displayExpr="name" />
      </dx-column>

    </dx-data-grid>
  </div>
</template>
  
<script>
import "devextreme/data/odata/store";
import DxDataGrid, {
  DxColumn,
  DxFilterRow,
  DxPager,
  DxPaging,
  DxEditing,
  DxForm,
  DxItem,
  DxRequiredRule,
  DxPopup,
  DxLookup
} from "devextreme-vue/data-grid";
import { createStore } from 'devextreme-aspnet-data-nojquery';

import appInfo from "@/app-info";
export default {
  setup() {
    const url = `${appInfo.baseUrl}/Menu`;
    const dataSource = createStore({
      key: 'id',
      loadUrl: `${url}/Read`,
      insertUrl: `${url}/Create`,
      updateUrl: `${url}/Update`,
      deleteUrl: `${url}/Delete`,
    });

    return {
      dataSource,
      appInfo
    };
  },
  methods: {
    onUploaded(cellInfo, e) {
      const response = JSON.parse(e.request.response);
      console.log(response, e);
      cellInfo.setValue(response.id);
    }
  },
  components: {
    DxDataGrid,
    DxColumn,
    DxFilterRow,
    DxPager,
    DxPaging,
    DxEditing,
    DxForm,
    DxItem,
    DxRequiredRule,
    DxPopup,
    DxLookup
  }
};
</script>
  