export default [
  {
    text: "Ana Sayfa",
    path: "/home",
    icon: "home"
  },
  {
    text: "Ana Düzen",
    icon: "floppy",
    path: "/dashboards"
  },
  {
    text: "Markalar",
    icon: "globe",
    path: "/brands"
  },
  {
    text: "Kategoriler",
    icon: "verticalaligntop",
    path: "/categories"
  },
  {
    text: "Ürünler",
    icon: "box",
    path: "/products"
  },
  {
    text: "Müşteriler",
    icon: "group",
    path: "/customers"
  },
  {
    text: "Siparişler",
    icon: "cart",
    path: "/orders"
  },
  {
    text: "Sistem Kullanıcıları",
    icon: "group",
    path: "/users"
  },
  // {
  //   text: "Menüler",
  //   icon: "group",
  //   path: "/menus"
  // },
  ];
