<template>
  <div>
    <h2 class="content-block">Ürünler</h2>

    <dx-data-grid class="dx-card wide-card" :data-source="dataSource" :focused-row-index="0" :show-borders="false"
      :focused-row-enabled="true" :column-auto-width="true" :column-hiding-enabled="true">
      <dx-paging :page-size="10" />
      <dx-pager :show-page-size-selector="true" :show-info="true" />
      <dx-filter-row :visible="true" />

      <dx-editing :allow-updating="true" :allow-adding="true" :allow-deleting="true" mode="popup">
        <dx-popup :drag-enabled="true" :show-title="true" :width="700" height="auto" title="Yeni Ürün Ekle" />
        <dx-form>
          <dx-item :col-count="2" :col-span="2" item-type="group">
            <dx-item data-field="code">
              <dx-required-rule message="Zorunlu Alan" />
            </dx-item>
            <dx-item data-field="name">
              <dx-required-rule message="Zorunlu Alan" />
            </dx-item>
            <dx-item data-field="fullName">
              <dx-required-rule message="Zorunlu Alan" />
            </dx-item>
            <dx-item data-field="shortDescription">
              <dx-required-rule message="Zorunlu Alan" />
            </dx-item>
            <dx-item data-field="description" :col-span="2">
              <dx-required-rule message="Zorunlu Alan" />
            </dx-item>
            <dx-item data-field="currency">
              <dx-required-rule message="Zorunlu Alan" />
            </dx-item>
            <dx-item data-field="price">
              <dx-required-rule message="Zorunlu Alan" />
            </dx-item>
            <dx-item data-field="isTaxIncluded" />
            <dx-item data-field="tax">
              <dx-required-rule message="Zorunlu Alan" />
            </dx-item>
            <dx-item data-field="brandId" />
          </dx-item>

        </dx-form>
      </dx-editing>

      <dx-column data-field="id" :visible="false" />

      <dx-column data-field="code" caption="Kod" />

      <dx-column data-field="slug" caption="Url" />

      <dx-column data-field="name" caption="İsim" />

      <dx-column data-field="fullName" caption="Tam İsim" />

      <dx-column data-field="shortDescription" caption="Kısa Açıklama" />

      <dx-column data-field="description" caption="Açıklama">
        <dx-form-item :editor-options="{ height: 100 }" editor-type="dxTextArea" />
      </dx-column>

      <dx-column data-field="currency" caption="Para Birimi" />

      <dx-column data-field="price" caption="Fiyat" />

      <dx-column data-field="isTaxIncluded" caption="Kdv Dahil">
        <DxFormItem editor-type="dxCheckBox" />
      </dx-column>

      <dx-column data-field="tax" caption="Kdv Oranı" />

      <dx-column data-field="taxedPrice" caption="Kdvli Fiyat" />

      <dx-column data-field="brandId" caption="Marka">
        <dx-lookup display-expr="name" value-expr="id" :data-source="brandsDataSource" />
      </dx-column>

      <dx-column data-field="mediaId" caption="Resim" :visible="false" />

      <DxMasterDetail :enabled="true" template="master-detail" />
      <template #master-detail="{ data }">
        <ProductDetails :master-detail-data="data" />
      </template>

    </dx-data-grid>
  </div>
</template>
  
<script>
import "devextreme/data/odata/store";
import 'devextreme-vue/text-area';
import DxDataGrid, {
  DxColumn,
  DxFilterRow,
  DxLookup,
  DxPager,
  DxPaging,
  DxEditing,
  DxForm,
  DxItem,
  DxRequiredRule,
  DxFormItem,
  DxPopup,
  DxMasterDetail
} from "devextreme-vue/data-grid";

import appInfo from "@/app-info";
import { createStore } from 'devextreme-aspnet-data-nojquery';
import ProductDetails from '@/components/product-details.vue';
export default {
  setup() {
    const url = `${appInfo.baseUrl}/`;
    const dataSource = createStore({
      key: 'id',
      loadUrl: `${url}Product/Read`,
      insertUrl: `${url}Product/Create`,
      updateUrl: `${url}Product/Update`,
      deleteUrl: `${url}Product/Delete`,
    });

    const categorieDataSource = createStore({
      key: 'id',
      loadUrl: `${url}Category/Read`,
    });

    const brandsDataSource = createStore({
      key: 'id',
      loadUrl: `${url}Brand/Read`,
    });

    return {
      dataSource,
      categorieDataSource,
      brandsDataSource
    };

  },
  components: {
    DxDataGrid,
    DxColumn,
    DxFilterRow,
    DxLookup,
    DxPager,
    DxPaging,
    DxEditing,
    DxForm,
    DxItem,
    DxRequiredRule,
    DxFormItem,
    DxPopup,
    DxMasterDetail,
    ProductDetails
  }
};
</script>
  