<template>
    <div>
        <DxDataGrid :data-source="dataSource" :show-borders="true">
            <dx-editing :allow-updating="true" :allow-adding="true" :allow-deleting="true" mode="popup">
                <dx-popup :drag-enabled="true" :show-title="true" :width="700" height="auto" title="Yeni Varyant Detay Ekle" />
                <dx-form>
                    <dx-item :col-count="2" :col-span="2" item-type="group">
                        <dx-item data-field="code" />
                        <dx-item data-field="name" />
                    </dx-item>
                    <dx-item data-field="description" />
                    <dx-item :col-count="2" :col-span="2" item-type="group">
                        <dx-item data-field="isPriceCalculate" />
                        <dx-item data-field="additionalPrice" />
                    </dx-item>
                </dx-form>
            </dx-editing>

            <DxPaging :page-size="5" />
            <DxColumn data-field="code" caption="Kod" />
            <DxColumn data-field="name" caption="İsim" />
            <DxColumn data-field="description" caption="Açıklama" />
            <DxColumn data-field="isPriceCalculate" caption="Fiyat Güncelle" />
            <DxColumn data-field="additionalPrice" caption="Artı Ücret" />
        </DxDataGrid>
    </div>
</template>
    
<script>
import DxDataGrid, { DxColumn, DxPopup, DxEditing, DxForm, DxItem } from 'devextreme-vue/data-grid';
import appInfo from "@/app-info";
import { createStore } from 'devextreme-aspnet-data-nojquery';

export default {
    components: {
        DxDataGrid,
        DxColumn,
        DxPopup,
        DxEditing,
        DxForm,
        DxItem
    },
    props: {
        masterDetailData: {
            type: Object,
            default: () => ({})
        }
    },
    setup(props) {
        const url = `${appInfo.baseUrl}/`;
        const dataSource = createStore({
            key: 'id',
            loadUrl: `${url}AttributeValues/Read/${props.masterDetailData.row.key}`,
            insertUrl: `${url}AttributeValues/Create/${props.masterDetailData.row.key}`,
            updateUrl: `${url}AttributeValues/Update`,
            deleteUrl: `${url}AttributeValues/Delete`,
        });

        return {
            dataSource
        };
    }
};
</script>