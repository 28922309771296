<template>
  <div>
    <h2 class="content-block">Sistem Kullanıcıları</h2>

    <dx-data-grid class="dx-card wide-card" :data-source="dataSource" :focused-row-index="0" :show-borders="false"
      :focused-row-enabled="true" :column-auto-width="true" :column-hiding-enabled="true">
      <dx-paging :page-size="10" />
      <dx-pager :show-page-size-selector="true" :show-info="true" />
      <dx-filter-row :visible="true" />

      <dx-editing :allow-updating="true" :allow-adding="true" :allow-deleting="true" mode="popup">
        <dx-popup :show-title="true" :width="350" height="auto" title="Yeni Sistem Kullanıcısı Ekle" />
        <dx-form>
          <dx-item :col-count="1" :col-span="2" item-type="group">
            <dx-item data-field="code">
              <dx-required-rule message="Zorunlu Alan" />
            </dx-item>
            <dx-item data-field="firstName">
              <dx-required-rule message="Zorunlu Alan" />
            </dx-item>
            <dx-item data-field="lastName">
              <dx-required-rule message="Zorunlu Alan" />
            </dx-item>
            <dx-item data-field="email">
              <dx-required-rule message="Zorunlu Alan" />
            </dx-item>
            <dx-item data-field="password">
              <dx-required-rule message="Zorunlu Alan" />
            </dx-item>

            <dx-item data-field="isAdmin" />
          </dx-item>

        </dx-form>
      </dx-editing>

      <dx-column data-field="code" caption="Kodu" data-type="text" />
      <!-- <dx-column data-field="companyName" caption="Firma Adı" data-type="text" /> -->
      <dx-column data-field="firstName" caption="Adı" data-type="text" />
      <dx-column data-field="lastName" caption="Soyadı" data-type="text" />
      <dx-column data-field="email" caption="Email" data-type="text" />
      <dx-column data-field="password" caption="Şifre" data-type="text" />
      <dx-column data-field="isAdmin" caption="Admin?" data-type="text">
        <dx-lookup :data-source="adminType" valueExpr="id" displayExpr="name" />
      </dx-column>
    </dx-data-grid>
  </div>
</template>

<script>
import "devextreme/data/odata/store";
import DxDataGrid, {
  
  DxColumn,
  DxFilterRow,
  DxPager,
  DxPaging,
  DxEditing,
  DxForm,
  DxItem,
  DxRequiredRule,
  DxPopup,
  DxLookup
} from "devextreme-vue/data-grid";

import { createStore } from 'devextreme-aspnet-data-nojquery';
import appInfo from "@/app-info";
export default {
  setup() {
    const url = `${appInfo.baseUrl}/User`;
    const dataSource = createStore({
      key: 'id',
      loadUrl: `${url}/Read?type=0`,
      insertUrl: `${url}/Create?type=0`,
      updateUrl: `${url}/Update`,
      deleteUrl: `${url}/Delete`,
    });

    const adminType = [
      {
        id: true,
        name: "Evet"
      },
      {
        id: false,
        name: "Hayır"
      }
    ];

    return {
      dataSource,
      adminType
    };
  },
  components: {
    DxDataGrid,
    DxColumn,
    DxFilterRow,
    DxPager,
    DxPaging,
    DxEditing,
    DxForm,
    DxItem,
    DxRequiredRule,
    DxPopup,
    DxLookup
  }
};
</script>