<template>
    <DxTabPanel>
        <DxItem title="Sipariş Detayları" template="orders-tab" />
        <template #orders-tab>
            <OrderItems :order-id="masterDetailData.key"/>
        </template>
        <DxItem title="Sipariş Hareketleri" template="address-tab" />
        <template #address-tab>
            <OrderHistories :order-id="masterDetailData.key"/>
        </template>
    </DxTabPanel>
</template>

<script>
import { DxTabPanel, DxItem } from 'devextreme-vue/tab-panel';
import OrderItems from './order-items.vue';
import OrderHistories from './order-histories.vue';
export default {
    components: {
        DxTabPanel,
        DxItem,
        OrderItems,
        OrderHistories
    },
    props: {
        masterDetailData: {
            type: Object,
            default: () => ({})
        }
    }
};
</script>