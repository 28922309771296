<template>
    <div v-if="masterDetailData.data.type == 1 || masterDetailData.data.type == 0">
        <DxTileView :items="items" :base-item-height="150" :base-item-width="150" :item-margin="10">
            <template #item="{ data }">
                <div class="dx-tile-content">
                    <div class="remove">
                        <DxButton icon="remove" @click="deleteImage(data)" />
                    </div>
                    <div :style="{ backgroundImage: `url(${data.url})` }" class="image" />
                </div>
            </template>
        </DxTileView>
        <div class="options">
            <div class="option">
                <div class="dx-field">
                    <div class="dx-field-label">Buton Yazısı</div>
                    <div class="dx-field-value">
                        <DxTextBox :value="addedButtonText" placeholder="" />
                    </div>
                </div>

                <DxFileUploader select-button-text="Resim Seç" name="file" :multiple="false" accept="image/*"
                    upload-mode="instantly" :upload-url="appInfo.baseUrl + '/media'"
                    @uploaded="(e) => onSliderUpload(e)" />
            </div>
        </div>
    </div>
    <div v-if="masterDetailData.data.type == 2">
        <DxDataGrid :data-source="dataSource" :show-borders="true">
            <dx-editing :allow-updating="false" :allow-adding="true" :allow-deleting="true" mode="popup">
                <dx-popup :drag-enabled="true" :show-title="true" :width="700" height="auto" title="Yeni Ekle" />
                <dx-form>
                    <dx-item :col-count="1" :col-span="2" item-type="group">
                        <dx-item data-field="title" />
                        <dx-item data-field="url" />
                        <dx-item data-field="actionText" />
                        <dx-item data-field="actionHref" />
                    </dx-item>
                </dx-form>
            </dx-editing>

            <DxPaging :page-size="5" />
            <DxColumn data-field="url" caption="Resim" cell-template="cellTemplate"
                edit-cell-template="editCellTemplate" />
            <DxColumn data-field="title" caption="Başlık" />
            <DxColumn data-field="actionText" caption="Buton Adı" />
            <DxColumn data-field="actionHref" caption="Buton Linki" />


            <template #cellTemplate="{ data }">
                <img style="width: 100px; object-fit: fill;" :src="`${data.value}`">
            </template>

            <template #editCellTemplate="{ data }">
                <div>
                    <dx-file-uploader name="file" :multiple="false" accept="image/*" upload-mode="instantly"
                        :upload-url="appInfo.baseUrl + '/media'" @uploaded="(e) => onUploaded(data, e)" />
                </div>
            </template>
        </DxDataGrid>
    </div>
    <div v-if="masterDetailData.data.type == 3">
        <DxDataGrid :data-source="dataSource" :show-borders="true">
            <dx-editing :allow-updating="false" :allow-adding="true" :allow-deleting="true" mode="popup">
                <dx-popup :drag-enabled="true" :show-title="true" :width="700" height="auto" title="Yeni Ekle" />
                <dx-form>
                    <dx-item :col-count="1" :col-span="2" item-type="group">
                        <dx-item data-field="productId" />
                    </dx-item>
                </dx-form>
            </dx-editing>

            <DxPaging :page-size="5" />
            <dx-column data-field="productId" caption="Gösterilecek Ürün" :visible="false">
                <dx-lookup display-expr="name" value-expr="id" :data-source="productDataSource" />
            </dx-column>
            <DxColumn data-field="product.code" caption="Ürün Kodu" />
            <DxColumn data-field="product.name" caption="Ürün Adı" />
        </DxDataGrid>
    </div>
    <div v-if="masterDetailData.data.type == 4">
        <DxDataGrid :data-source="dataSource" :show-borders="true">
            <dx-editing :allow-updating="false" :allow-adding="true" :allow-deleting="true" mode="popup">
                <dx-popup :drag-enabled="true" :show-title="true" :width="700" height="auto" title="Yeni Ekle" />
                <dx-form>
                    <dx-item :col-count="1" :col-span="2" item-type="group">
                        <dx-item data-field="title" />
                        <dx-item data-field="url" />
                        <dx-item data-field="actionText" />
                    </dx-item>
                </dx-form>
            </dx-editing>

            <DxPaging :page-size="5" />
            <DxColumn data-field="url" caption="Link" cell-template="cellTemplate"
                edit-cell-template="editCellTemplate" />
            <DxColumn data-field="title" caption="Başlık" />
            <DxColumn data-field="actionText" caption="Buton Adı" />

            <template #cellTemplate="{ data }">
                <a :href="`${data.value}`" target="_blank">Linke Git</a>
            </template>

            <template #editCellTemplate="{ data }">
                <div>
                    <dx-file-uploader name="file" :multiple="false" accept="application/pdf" upload-mode="instantly"
                        :upload-url="appInfo.baseUrl + '/media'" @uploaded="(e) => onUploaded(data, e)" />
                </div>
            </template>
        </DxDataGrid>
    </div>
</template>

<script>
import DxTileView from 'devextreme-vue/tile-view';
import axios from "axios";
import appInfo from "@/app-info";
import { DxFileUploader } from 'devextreme-vue/file-uploader';
import DxButton from 'devextreme-vue/button';
import DxDataGrid, { DxColumn, DxPopup, DxEditing, DxForm, DxItem, DxLookup } from 'devextreme-vue/data-grid';
import { createStore } from 'devextreme-aspnet-data-nojquery';
import { DxTextBox } from 'devextreme-vue';
export default {
    components: {
        DxTileView,
        DxFileUploader,
        DxButton,
        DxDataGrid,
        DxColumn,
        DxPopup,
        DxEditing,
        DxForm,
        DxItem,
        DxLookup,
        DxTextBox
    },
    props: {
        masterDetailData: {
            type: Object,
            default: () => ({})
        }
    },
    data() {
        return {
            items: [],
            addedButtonText: "Ürünleri İncele",
            appInfo,
            dataSource: null,
            productDataSource: createStore({
                key: 'id',
                loadUrl: `${appInfo.baseUrl}/Product/Read`,
            }),
        };
    },
    methods: {
        async loadData() {
            const url = `${appInfo.baseUrl}/`;
            const response = await axios.get(`${url}Dashboard/Items/${this.masterDetailData.data.id}`);
            this.items = response.data;
        },
        async onSliderUpload(e) {
            const url = `${appInfo.baseUrl}/`;

            const response = JSON.parse(e.request.response);
            var formData = new FormData();
            formData.append("values", JSON.stringify({
                url: response.url,
                actionText: this.addedButtonText || "Ürünleri İncele",
            }));

            await axios.post(`${url}Dashboard/Items/${this.masterDetailData.data.id}`, formData);
            this.loadData();
        },
        async deleteImage(data) {
            const url = `${appInfo.baseUrl}/`;
            await axios.delete(`${url}Dashboard/Items/${data.id}`);
            this.loadData();
        },
        onUploaded(cellInfo, e) {
            const response = JSON.parse(e.request.response);
            cellInfo.setValue(response.url);
        }
    },
    created() {
        const url = `${appInfo.baseUrl}/`;
        if (this.masterDetailData.data.type == 2 || this.masterDetailData.data.type == 3 || this.masterDetailData.data.type == 4)
            this.dataSource = createStore({
                key: 'id',
                loadUrl: `${url}Dashboard/Items/${this.masterDetailData.data.id}`,
                insertUrl: `${url}Dashboard/Items/${this.masterDetailData.data.id}`,
                updateUrl: `${url}Dashboard/Items/${this.masterDetailData.data.id}`,
                deleteUrl: `${url}Dashboard/Items`,
            });
        else
            this.loadData();
    },
};
</script>

<style scoped>
.dx-tile-content {
    height: 100%;
    padding: 0;
    position: relative;
}

.dx-tile-content .image {
    height: 100%;
    width: 100%;
    background-position: center;
    background-size: cover;
    padding: 10px;
    display: block;
}

.dx-tile {
    border: 1px solid #999;
}

.dx-tile.dx-state-hover {
    border-color: #eee;
}

.dx-tile-content .remove {
    position: absolute;
    opacity: 0.6;
    padding: 2px;
    right: 5px;
    top: 5px;
}

.dx-tile-content .cover {
    position: absolute;
    opacity: 0.6;
    padding: 2px;
    left: 5px;
    top: 5px;
}
</style>