<template>
    <div>
      <DxDataGrid :data-source="dataSource" :show-borders="true">
        <DxPaging :page-size="5" />
        <DxColumn data-field="product.code" caption="Ürün Kodu" />
        <DxColumn data-field="product.name" caption="Ürün Adı" />
        <DxColumn data-field="price" caption="Birim Fiyatı" />
        <DxColumn data-field="quantity" caption="Adet" />
        <DxColumn data-field="totalPrice" caption="Toplam Fiyat" />
      </DxDataGrid>
    </div>
  </template>
    
  <script>
  import DxDataGrid, { DxColumn} from 'devextreme-vue/data-grid';
  import appInfo from "@/app-info";
  import { createStore } from 'devextreme-aspnet-data-nojquery';
  
  export default {
    components: {
      DxDataGrid,
      DxColumn,
    },
    props: {
      orderId: {
        type: String,
        default: null
      }
    },
    setup(props) {
      const url = `${appInfo.baseUrl}/`;
      const dataSource = createStore({
        key: 'id',
        loadUrl: `${url}OrderDetail/DetailRead/${props.orderId}`,
      });

      return {
        dataSource
      };
    }
  };
  </script>