<template>
  <div>
    <h2 class="content-block">Ana Sayfa Düzeni</h2>

    <dx-data-grid class="dx-card wide-card" :data-source="dataSource" :focused-row-index="0" :show-borders="false"
      :focused-row-enabled="true" :column-auto-width="true" :column-hiding-enabled="true">
      <dx-paging :page-size="10" />
      <dx-pager :show-page-size-selector="true" :show-info="true" />
      <dx-filter-row :visible="true" />

      <DxRowDragging :allow-reordering="true" :on-reorder="onReorder" drop-feedback-mode="push" />

      <dx-editing :allow-updating="true" :allow-adding="true" :allow-deleting="true" mode="popup">
        <dx-popup :show-title="true" :width="350" height="auto" title="Yeni Sistem Kullanıcısı Ekle" />
        <dx-form>
          <dx-item :col-count="1" :col-span="2" item-type="group">
            <dx-item data-field="name">
              <dx-required-rule message="Zorunlu Alan" />
            </dx-item>
            <dx-item data-field="description" />
            <dx-item data-field="type">
              <dx-required-rule message="Zorunlu Alan" />
            </dx-item>
            <dx-item data-field="isShowPrice" />
          </dx-item>
        </dx-form>
      </dx-editing>

      <dx-column data-field="viewOrder" caption="Sıra" :visible="false" />
      <dx-column data-field="name" caption="Başlık" data-type="text" />
      <!-- <dx-column data-field="companyName" caption="Firma Adı" data-type="text" /> -->
      <dx-column data-field="description" caption="Detay" data-type="text" />
      <dx-column data-field="slug" caption="Url" data-type="text" />
      <dx-column data-field="type" caption="Tip" data-type="text">
        <dx-lookup display-expr="name" value-expr="id" :data-source="types" />
      </dx-column>
      <dx-column data-field="isShowPrice" caption="Fiyat Görüntüle" data-type="text">
        <dx-lookup display-expr="name" value-expr="id" :data-source="priceType" />
      </dx-column>

      <DxMasterDetail :enabled="true" template="master-detail" />
      <template #master-detail="{ data }">
        <DashboardDetails :master-detail-data="data" />
      </template>

    </dx-data-grid>
  </div>
</template>

<script>
import "devextreme/data/odata/store";
import DxDataGrid, {
  DxColumn,
  DxFilterRow,
  DxPager,
  DxPaging,
  DxEditing,
  DxForm,
  DxItem,
  DxRequiredRule,
  DxPopup,
  DxLookup,
  DxMasterDetail,
  DxRowDragging
} from "devextreme-vue/data-grid";

import { createStore } from 'devextreme-aspnet-data-nojquery';
import appInfo from "@/app-info";
import DashboardDetails from "@/components/dashboard-details.vue";
export default {
  setup() {
    const url = `${appInfo.baseUrl}/Dashboard`;
    const dataSource = createStore({
      key: 'id',
      loadUrl: `${url}/Read`,
      insertUrl: `${url}/Create`,
      updateUrl: `${url}/Update`,
      deleteUrl: `${url}/Delete`,
    });

    const types = [
      {
        id: 0,
        name: "Banner"
      },
      {
        id: 1,
        name: "Slider"
      },
      {
        id: 2,
        name: "Kart"
      },
      {
        id: 3,
        name: "Ürün Görünümü"
      },
      {
        id: 4,
        name: "Katalog"
      }
    ];

    const priceType = [
      {
        id: true,
        name: "Göster"
      },
      {
        id: false,
        name: "Gizle"
      }
    ];

    const processReorder = async (e) => {
      const visibleRows = e.component.getVisibleRows();
      console.log(visibleRows);
      const newOrderIndex = visibleRows[e.toIndex].rowIndex;
      console.log(newOrderIndex);
      await dataSource.update(e.itemData.id, { viewOrder: newOrderIndex });
      await e.component.refresh();
    };

    const onReorder = (e) => {
      e.promise = processReorder(e);
    };



    return {
      dataSource,
      types,
      priceType,
      onReorder
    };
  },
  components: {
    DxDataGrid,
    DxColumn,
    DxFilterRow,
    DxPager,
    DxPaging,
    DxEditing,
    DxForm,
    DxItem,
    DxRequiredRule,
    DxPopup,
    DxLookup,
    DxMasterDetail,
    DashboardDetails,
    DxRowDragging
  }
};
</script>