<template>
  <div>
    <h2 class="content-block">Müşteriler</h2>

    <dx-data-grid class="dx-card wide-card" :data-source="dataSource" :show-borders="false" :focused-row-enabled="true"
      :column-auto-width="true" :column-hiding-enabled="true" :hover-state-enabled="true" @row-click="rowClick">
      <dx-paging :page-size="10" />
      <dx-pager :show-page-size-selector="true" :show-info="true" />
      <dx-filter-row :visible="true" />

      <dx-editing refresh-mode="full" :allow-updating="true" :allow-adding="true" :allow-deleting="true"
        :confirm-delete="true" mode="popup">
        <dx-popup :show-title="true" :width="350" height="auto" title="Yeni Müşteri Ekle" />
        <dx-form>
          <dx-item :col-count="1" :col-span="2" item-type="group">
            <dx-item data-field="code">
              <dx-required-rule message="Zorunlu Alan" />
            </dx-item>
            <dx-item data-field="companyName">
              <dx-required-rule message="Zorunlu Alan" />
            </dx-item>
            <dx-item data-field="firstName">
              <dx-required-rule message="Zorunlu Alan" />
            </dx-item>
            <dx-item data-field="lastName">
              <dx-required-rule message="Zorunlu Alan" />
            </dx-item>
            <dx-item data-field="email">
              <dx-required-rule message="Zorunlu Alan" />
            </dx-item>
            <dx-item data-field="password">
              <dx-required-rule message="Zorunlu Alan" />
            </dx-item>
          </dx-item>

        </dx-form>
      </dx-editing>

      <dx-column data-field="code" caption="Müşteri Kodu" data-type="text" />
      <dx-column data-field="companyName" caption="Firma Adı" data-type="text" />
      <dx-column data-field="firstName" caption="Müşteri Adı" data-type="text" />
      <dx-column data-field="lastName" caption="Müşteri Soyadı" data-type="text" />
      <dx-column data-field="email" caption="Email" data-type="text" />
      <dx-column data-field="password" caption="Şifre" data-type="text" />

      <DxColumn type="buttons">
        <DxButton name="edit" />
        <DxButton name="delete" />
        <DxButton icon="info"  @click="openDetails"/>
      </DxColumn>

    </dx-data-grid>

    <customer-detail
      :is-panel-opened="isPanelOpened"
      @close="onClose"
      @pin-changed="onPanelPinChanged"
      :customer-data="selectedCustomer"
    />

  </div>
</template>

<script>
import "devextreme/data/odata/store";
import DxDataGrid, {
  DxColumn,
  DxFilterRow,
  DxPager,
  DxPaging,
  DxEditing,
  DxForm,
  DxItem,
  DxRequiredRule,
  DxPopup,
  DxButton
} from "devextreme-vue/data-grid";
import { createStore } from 'devextreme-aspnet-data-nojquery';
import appInfo from "@/app-info";
import CustomerDetail from "./customer-detail-page";
import { ref } from "vue";
export default {
  data() {
    return {
      currentEmployee: {},
    };
  },
  methods: {
    onSelectionChanged(row) {
      const data = row.selectedRowsData[0];
      console.log(data);
    },
    openDetails(e) {
      this.currentEmployee = e.row.data;
    }
  },
  setup() {
    const isPanelOpened = ref(false);
    const selectedCustomer = ref(null);

    const onPanelPinChanged = (e) => {
      isPanelOpened.value = e;
    };
    const onClose = () => {
      isPanelOpened.value = false;
    };


    const url = `${appInfo.baseUrl}/User`;
    const dataSource = createStore({
      key: 'id',
      loadUrl: `${url}/Read?type=1`,
      insertUrl: `${url}/Create?type=1`,
      updateUrl: `${url}/Update`,
      deleteUrl: `${url}/Delete`,
    });


    const rowClick = (e) => {
      isPanelOpened.value = true;
      selectedCustomer.value = e.data;
    };
    return {
      dataSource,
      rowClick,
      isPanelOpened,
      onPanelPinChanged,
      onClose,
      selectedCustomer
    };
  },
  components: {
    DxDataGrid,
    DxColumn,
    DxFilterRow,
    DxPager,
    DxPaging,
    DxEditing,
    DxForm,
    DxItem,
    DxRequiredRule,
    DxPopup,
    DxButton,
    CustomerDetail
  }
};
</script>