<template>
    <DxTabPanel>
        <DxItem title="Kategoriler" template="orders-tab" />
        <template #orders-tab>
            <ProductCategoriesDetail :product-id="masterDetailData.key" />
        </template>
        <DxItem title="Resimler" template="address-tab" />
        <template #address-tab>
            <ProductMediaDetail :product-id="masterDetailData.key" />
        </template>
        <DxItem title="Varyantlar" template="variant-tab" />
        <template #variant-tab>
            <ProductVariant :product-id="masterDetailData.key" />
        </template>
    </DxTabPanel>
</template>
  
<script>
import { DxTabPanel, DxItem } from 'devextreme-vue/tab-panel';
import ProductCategoriesDetail from './product-categories-detail.vue';
import ProductMediaDetail from './product-media-detail.vue';
import ProductVariant from './product-variant.vue';

export default {
    components: {
        DxTabPanel,
        DxItem,
        ProductCategoriesDetail,
        ProductMediaDetail,
        ProductVariant
    },
    props: {
        masterDetailData: {
            type: Object,
            default: () => ({})
        }
    }
};
</script>