<template>
    <div id="contact-panel" class="panel" :class="{ open: isPanelOpened }">
        <div class="data-wrapper">
            <dx-load-panel :visible="isLoading" :show-pane="false" :width="300" container="#contact-panel"
                :position="{ of: '#contact-panel' }" />
            <template v-if="customerData">
                <dx-toolbar class="panel-toolbar">
                    <dx-item location="before">
                        <div class="contact-name">
                            {{ customerData?.firstName }} {{ customerData?.lastName }}
                        </div>
                    </dx-item>

                    <dx-item location="after" widget="dxButton" :options="{
                        icon: 'refresh',
                        stylingMode: 'text',
                        onClick: loadDatas
                    }" />

                    <dx-item location="after" widget="dxButton" :options="{
                        icon: 'close',
                        stylingMode: 'text',
                        onClick: onClose
                    }" />
                </dx-toolbar>

                <dx-scroll-view class="panel-scroll">
                    <div class="data-part">
                        <DxAccordion :data-source="items" :collapsible="true" :multiple="true" :animation-duration="300"
                            :selected-items="0" id="accordion-container">
                            <template #item="{ data }">
                                <div v-if="data.id == 1">
                                    <div v-for="item in data.items" :key="item.id">
                                        <div class="dx-field">
                                            <div class="dx-field-label">{{ item.name }}</div>
                                            <div class="dx-field-value">{{ item.fullAddress }}</div>
                                        </div>
                                    </div>
                                </div>
                                <div v-else>
                                    <div v-for="item in data.items" :key="item.id">
                                        <div class="dx-field">
                                            <div class="dx-field-label">{{ item.code }}</div>
                                            <div class="dx-field-value">{{ item.generalTotal }}</div>
                                        </div>
                                    </div>
                                </div>
                            </template>
                        </DxAccordion>
                    </div>
                </dx-scroll-view>
            </template>
        </div>
    </div>
</template>

<script>
import { DxAccordion } from 'devextreme-vue/accordion';
import { DxScrollView } from 'devextreme-vue/scroll-view';
import { DxToolbar, DxItem } from 'devextreme-vue/toolbar';
import DxLoadPanel from 'devextreme-vue/load-panel';
import axios from "axios";
import appInfo from "@/app-info";

export default {
    components: {
        DxAccordion,
        DxScrollView,
        DxToolbar,
        DxItem,
        DxLoadPanel
    },
    props: {
        isPanelOpened: {
            type: Boolean,
            default: false
        },
        customerData: {
            type: Object,
            default: null
        }
    },
    data() {
        return {
            isLoading: false,
            panelData: {
                name: "Merhaba"
            },
            items: [
                {
                    id: 1,
                    title: "Adresler",
                    items: []
                },
                {
                    id: 2,
                    title: "Siparişler",
                    items: []
                }
            ],
        };
    },
    methods: {
        onClose() {
            this.$emit('close');
        },
        async loadDatas() {
            this.isLoading = true;
            const url = `${appInfo.baseUrl}/`;
            const response = await axios.get(`${url}User/Address/${this.customerData.id}`);
            this.items[0].items = response.data;


            const orderResponse = await axios.get(`${url}User/Orders/${this.customerData.id}`);
            this.items[1].items = orderResponse.data;
            this.isLoading = false;
        },
    },
    watch: {
        customerData: {
            handler: function (val) {
                if (val) {
                    this.loadDatas();
                }
            }
        }
    },
};
</script>
  
<style lang="scss">
#contact-panel {
    --contact-side-panel-width: 450px;
    --base-bg: #fff;
    --border-color: #e0e0e0;
}

.screen-x-small #contact-panel {
    --contact-side-panel-width: 100vw;
}

.panel {
    position: absolute;
    top: 0;
    bottom: 0;
    right: calc(-1 * var(--contact-side-panel-width));
    background: var(--base-bg);
    transition: right 400ms;
}

.panel :deep(.dx-layout-manager-one-col) .dx-box-item .dx-single-column-item-content .contact-fields-group {
    padding-top: 20px;
}

.panel :deep(.photo-row) .dx-item>.dx-item-content>.dx-item:first-child {
    max-width: 144px;
}

.panel :deep(.photo-row) .dx-item>.dx-item-content>.dx-item:first-child .photo-box {
    padding-bottom: 0;
}

.embedded.dx-viewport .panel {
    top: 0;
}

.panel.open {
    right: 0;
    box-shadow: 0 0 16px var(--border-color);
}

.panel.pin {
    position: absolute;
    transition: none;
    box-shadow: none;
    border-left: 1px solid var(--border-color);
}

.panel.pin.open {
    top: 0;
    position: relative;
}

.panel .data-wrapper {
    padding-bottom: var(--toolbar-vertical-padding);
    height: 100%;
    width: var(--contact-side-panel-width);
}

.panel .data-wrapper .panel-toolbar {
    --padding: calc(var(--toolbar-vertical-padding) / 2);
    padding: var(--padding) var(--padding) var(--padding) var(--toolbar-vertical-padding);
}

.panel .data-wrapper .data-part {
    padding: 0 16px;
}

.panel .data-wrapper .data-part.border {
    border-bottom: 1px solid var(--border-color);
}

.panel .data-wrapper .data-part-toolbar {
    padding: 10px 16px;
    margin-bottom: var(--toolbar-margin-bottom);
}

.panel .data-wrapper .data-part-toolbar .dx-button {
    min-width: 90px;
}

.panel .data-wrapper .panel-scroll {
    height: calc(100% - 20px);
}

.panel .data-wrapper .contact-name {
    font-weight: bold;
    margin-right: 8px;
}

.panel .data-wrapper .opportunities {
    padding-bottom: 10px;
}

.dx-toolbar-before {
    left: 10px;
}

#data-part {
    height: 700px;
}

#data-part h1 {
    font-size: 20px;
}

#data-part h1,
#data-part p {
    margin: 0;
}

.dx-theme-material #data-part .dx-accordion-item-title {
    display: flex;
}

.dx-theme-material #data-part h1 {
    align-self: center;
}
</style>