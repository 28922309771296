<template>
  <div>
    <DxDataGrid :data-source="dataSource" :show-borders="true">

      <dx-editing :allow-updating="false" :allow-adding="true" :allow-deleting="true" mode="popup">
        <dx-popup :drag-enabled="true" :show-title="true" :width="700" height="auto" title="Yeni Kategori Ekle" />
        <dx-form>
          <dx-item :col-count="1" :col-span="2" item-type="group">
            <dx-item data-field="categoryId" />
          </dx-item>
        </dx-form>
      </dx-editing>

      <DxPaging :page-size="5" />
      <dx-column data-field="categoryId" caption="Kategori" :visible="false">
        <dx-lookup display-expr="name" value-expr="id" :data-source="categorieDataSource" />
      </dx-column>
      <DxColumn data-field="category.code" caption="Kod" />
      <DxColumn data-field="category.name" caption="İsim" />
    </DxDataGrid>
  </div>
</template>
  
<script>
import DxDataGrid, { DxColumn, DxPopup, DxEditing, DxForm,DxItem,DxLookup } from 'devextreme-vue/data-grid';
import appInfo from "@/app-info";
import { createStore } from 'devextreme-aspnet-data-nojquery';

export default {
  components: {
    DxDataGrid,
    DxColumn,
    DxPopup,
    DxEditing,
    DxForm,
    DxItem,
    DxLookup
  },
  props: {
    productId: {
      type: String,
      default: null
    }
  },
  setup(props) {
    const url = `${appInfo.baseUrl}/`;
    const dataSource = createStore({
      key: 'id',
      loadUrl: `${url}ProductCategory/Read/${props.productId}`,
      insertUrl: `${url}ProductCategory/Create/${props.productId}`,
      updateUrl: `${url}ProductCategory/Update/${props.productId}`,
      deleteUrl: `${url}ProductCategory/Delete`,
    });

    const categorieDataSource = createStore({
      key: 'id',
      loadUrl: `${url}Category/Read`,
    });


    return {
      dataSource,
      categorieDataSource,
    };
  }
};
</script>