<template>
  <div>
    <h2 class="content-block">Kategoriler</h2>

    <dx-data-grid class="dx-card wide-card" :data-source="dataSource" :show-borders="false" :focused-row-enabled="true"
      :column-auto-width="true">
      <dx-paging :page-size="10" />
      <dx-pager :show-page-size-selector="true" :show-info="true" />
      <dx-filter-row :visible="true" />

      <dx-editing :allow-updating="true" :allow-adding="true" :allow-deleting="true" mode="popup">
        <dx-popup :show-title="true" :width="350" height="auto" title="Yeni Kategori Ekle" />
        <dx-form>
          <dx-item :col-count="1" :col-span="2" item-type="group">
            <dx-item data-field="code">
              <dx-required-rule message="Zorunlu Alan" />
            </dx-item>
            <dx-item data-field="name">
              <dx-required-rule message="Zorunlu Alan" />
            </dx-item>
          </dx-item>
          <dx-item data-field="parentId" />
          
          <dx-item data-field="mediaId" />

        </dx-form>
      </dx-editing>

      <dx-column data-field="id" :visible="false" />


      <dx-column data-field="mediaId" caption="Resim" edit-cell-template="editCellTemplate" :visible="false" />

      <dx-column data-field="media.url" caption="Resim" :width="150" cell-template="cellTemplate" />

      <dx-column data-field="code" caption="Kod" />

      <dx-column data-field="name" caption="Adı" />

      <dx-column data-field="parentId" caption="Üst Kategori">
        <dx-lookup display-expr="name" value-expr="id" :data-source="parentDataSource" />
      </dx-column>

      <dx-column data-field="slug" caption="Url" />

      <template #cellTemplate="{ data }">
        <img style="width: 100px; object-fit: fill;" :src="`${data.value}`">
      </template>

      <template #editCellTemplate="{ data }">
        <div>
          <dx-file-uploader name="file" :multiple="false" accept="image/*" upload-mode="instantly"
            :upload-url="appInfo.baseUrl + '/media'" @uploaded="(e) => onUploaded(data, e)" />
          <!-- <dx-button class="retryButton" text="Retry" :visible="retryButtonVisible" @click="onClick" /> -->
        </div>
      </template>

    </dx-data-grid>
  </div>
</template>
  
<script>
import "devextreme/data/odata/store";
import DxDataGrid, {
  DxColumn,
  DxFilterRow,
  DxLookup,
  DxPager,
  DxPaging,
  DxEditing,
  DxForm,
  DxItem,
  DxRequiredRule,
  DxPopup
} from "devextreme-vue/data-grid";
import { DxFileUploader } from 'devextreme-vue/file-uploader';
import { createStore } from 'devextreme-aspnet-data-nojquery';

import appInfo from "@/app-info";
export default {
  setup() {
    const url = `${appInfo.baseUrl}/Category`;
    const dataSource = createStore({
      key: 'id',
      loadUrl: `${url}/Read`,
      insertUrl: `${url}/Create`,
      updateUrl: `${url}/Update`,
      deleteUrl: `${url}/Delete`,
    });

    const parentDataSource = createStore({
      key: 'id',
      loadUrl: `${url}/Read`,
    });

    return {
      dataSource,
      parentDataSource,
      appInfo
    };
  },
  methods: {
    onUploaded(cellInfo, e) {
      const response = JSON.parse(e.request.response);
      console.log(response, e);
      cellInfo.setValue(response.id);
    }
  },
  components: {
    DxDataGrid,
    DxColumn,
    DxFilterRow,
    DxLookup,
    DxPager,
    DxPaging,
    DxEditing,
    DxForm,
    DxItem,
    DxRequiredRule,
    DxFileUploader,
    DxPopup
  }
};
</script>
  