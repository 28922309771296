<template>
  <div>
    <h2 class="content-block">Siparişler</h2>
    <DxTabs :data-source="orderTypes" v-model:selected-index="selectedOrderTypes" />
    <dx-data-grid class="dx-card wide-card" :data-source="dataSource" :focused-row-index="0" :show-borders="false"
      :focused-row-enabled="true" :column-auto-width="true" :column-hiding-enabled="true">
      <dx-paging :page-size="10" />
      <dx-pager :show-page-size-selector="true" :show-info="true" />
      <dx-filter-row :visible="true" />

      <dx-editing :allow-updating="true" :allow-adding="false" :allow-deleting="true" mode="popup">
        <dx-popup :show-title="true" :width="350" height="auto" title="Sipariş Düzenle" />
        <dx-form>
          <dx-item :col-count="1" :col-span="2" item-type="group">
            <dx-item data-field="isSync">
            </dx-item>
            <dx-item data-field="status">
              <dx-required-rule message="Zorunlu Alan" />
            </dx-item>
            <dx-item data-field="statusNote" />
          </dx-item>
        </dx-form>
      </dx-editing>

      <dx-column data-field="code" caption="Kodu" data-type="text" />
      <dx-column data-field="orderDate" caption="Sipariş Tarihi" data-type="datetime" />
      <dx-column data-field="customer.firstName" caption="Müşteri Adı" data-type="text" />
      <dx-column data-field="customer.lastName" caption="Müşteri Soyadı" />
      <dx-column data-field="billingAddress.fullAddress" caption="Fatura Adresi" />
      <dx-column data-field="deliveryAddress.fullAddress" caption="Teslimat Adresi" />
      <dx-column data-field="totalPrice" caption="Sipariş Toplam Tutarı" />
      <dx-column data-field="totalTax" caption="Sipariş Toplam KDV Tutarı" />
      <dx-column data-field="generalTotal" caption="Sipariş Genel Toplam Tutarı" />
      <dx-column data-field="isSync" caption="Sipariş Senkronize Durumu" />
      <dx-column data-field="status" caption="Sipariş Durumu" :visible="false">
        <DxLookup :data-source="orderTypes" value-expr="id" display-expr="text" />
      </dx-column>
      <dx-column data-field="statusNote"
        caption="Durum Açıklaması (Kullanıcının Sipariş Hareketlerinde Görüntüleyeceği Açıklama)" :visible="false" />

      <DxMasterDetail :enabled="true" template="master-detail" />
      <template #master-detail="{ data }">
        <OrderDetails :master-detail-data="data" />
      </template>

    </dx-data-grid>
  </div>
</template>

<script>
import "devextreme/data/odata/store";
import DxDataGrid, {
  DxColumn,
  DxFilterRow,
  DxPager,
  DxPaging,
  DxEditing,
  DxForm,
  DxItem,
  DxRequiredRule,
  DxPopup,
  DxMasterDetail,
  DxLookup
} from "devextreme-vue/data-grid";

import { createStore } from 'devextreme-aspnet-data-nojquery';
import appInfo from "@/app-info";
import DxTabs from 'devextreme-vue/tabs';
import { ref, computed } from 'vue'
import OrderDetails from "@/components/order-details.vue";
export default {
  setup() {
    const selectedOrderTypes = ref(0);
    const url = `${appInfo.baseUrl}/Order`;

    const dataSource = computed(() => {
      return createStore({
        key: 'id',
        loadUrl: `${url}/Read?status=${selectedOrderTypes.value}`,
        updateUrl: `${url}/Update`,
        deleteUrl: `${url}/Delete`,
      });
    });

    const orderTypes = [
      { text: "Bekleyen Siparişler", id: 0 },
      { text: "Teslim Edilmiş Siparişler", id: 1 },
      { text: "İptal Edilen Siparişler", id: 2 },
      { text: "Kapatılan Siparişler", id: 3 },
    ];

    return {
      dataSource,
      orderTypes,
      selectedOrderTypes
    };
  },
  components: {
    DxDataGrid,
    DxColumn,
    DxFilterRow,
    DxPager,
    DxPaging,
    DxEditing,
    DxForm,
    DxItem,
    DxRequiredRule,
    DxPopup,
    DxTabs,
    DxMasterDetail,
    OrderDetails,
    DxLookup
  }
};
</script>