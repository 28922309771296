<template>
  <div>
    <DxTileView :items="images" :base-item-height="150" :base-item-width="150" :item-margin="10">
      <template #item="{ data }">
        <div class="dx-tile-content">
          <!-- <div class="cover">
            <DxButton icon="add" @click="coverImage(data)" />
          </div> -->
          <div class="remove">
            <DxButton icon="remove" @click="deleteImage(data)" />
          </div>
          <div :style="{ backgroundImage: `url(${data.media.url})` }" class="image" />
        </div>
      </template>
      <!-- <template #item="{ data }">
        <div :style="{ backgroundImage: `url(${data.media.url})`, backgroundSize: 'cover' }" class="dx-tile-image" />
      </template> -->
    </DxTileView>
    <div class="options">
      <div class="option">
        <DxFileUploader select-button-text="Resim Seç" name="file" :multiple="false" accept="image/*"
          upload-mode="instantly" :upload-url="appInfo.baseUrl + '/media'" @uploaded="(e) => onUploaded(e)" />
      </div>
    </div>
  </div>
</template>

<script>
import DxTileView from 'devextreme-vue/tile-view';
import axios from "axios";
import appInfo from "@/app-info";
import { DxFileUploader } from 'devextreme-vue/file-uploader';
import DxButton from 'devextreme-vue/button';
export default {
  components: {
    DxTileView,
    DxFileUploader,
    DxButton
  },
  data() {
    return {
      images: [],
      appInfo
    };
  },
  props: {
    productId: {
      type: String,
      default: null
    }
  },
  methods: {
    async loadData() {
      const url = `${appInfo.baseUrl}/`;
      const response = await axios.get(`${url}Product/Medias/${this.productId}`);
      this.images = response.data;
    },
    async onUploaded(e) {
      const url = `${appInfo.baseUrl}/`;
      const response = JSON.parse(e.request.response);
      await axios.post(`${url}Product/Medias/${this.productId}/${response.id}`);
      this.loadData();
    },
    async deleteImage(data) {
      const url = `${appInfo.baseUrl}/`;
      await axios.delete(`${url}Product/Medias/${data.id}`);
      this.loadData();
    }
  },
  created() {
    this.loadData();
  }
};
</script>

<style scoped>
.dx-tile-content {
  height: 100%;
  padding: 0;
  position: relative;
}

.dx-tile-content .image {
  height: 100%;
  width: 100%;
  background-position: center;
  background-size: cover;
  padding: 10px;
  display: block;
}

.dx-tile {
  border: 1px solid #999;
}

.dx-tile.dx-state-hover {
  border-color: #eee;
}

.dx-tile-content .remove {
  position: absolute;
  opacity: 0.6;
  padding: 2px;
  right: 5px;
  top: 5px;
}

.dx-tile-content .cover {
  position: absolute;
  opacity: 0.6;
  padding: 2px;
  left: 5px;
  top: 5px;
}

</style>